#redirection {
  margin-top: 5rem;
  text-align: center;
  font-size: medium;
}
#functionality-note {
  font-size: 1.2rem;
  color: rgb(128, 128, 128);
  margin: 30px;
}
