#carousel-settings {
  display: flex;
  width: min(90%, 1300px);
  min-height: 300px;
  margin: 30px auto;
  padding: 30px;
  justify-content: space-around;
  align-items: center;
  background-color: #f0d9ff;
  border-radius: 30px;
  #sliders {
    display: flex;
    justify-content: space-between;
  }
  #gap-and-image {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-evenly;
  }
}

@media (max-width: 1200px) {
  #carousel-settings {
    justify-content: space-between;
  }
}
@media (max-width: 700px) {
  #carousel-settings {
    flex-direction: column;
    & > * {
      margin: 20px;
    }
  }
}
