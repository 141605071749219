body,
html {
  padding: 0px;
  margin: 0;
  background-color: #f7d9d9;
}

.container {
  align-items: center;
  // height: 400px;
  padding: 30px;
  width: min(1300px, 95vw);
  justify-content: center;
  background-color: #f25287;
  margin: auto;
  margin-top: 30px;
  border-radius: 30px;

  .element {
    user-select: none;
    display: flex;
    justify-content: center;
    img {
      border-radius: 20px;
      height: 300px;
      // min-width: 500px;
      user-select: none;
    }
  }
}
@media (max-width: 800px) {
  img {
    height: 200px !important;
  }
}
@media (max-width: 600px) {
  img {
    height: 150px !important;
  }
}
