#carousel-gap {
  display: block;
  margin: 10px auto;
}
#carousel-mouse-speed {
  // height: 500px;
  display: block;
  margin: 10px auto;
}
#carousel-touch-speed {
  display: block;
  margin: 10px auto;
}
